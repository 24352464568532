html, body {
  height: 100%; /* Ensure the body takes up the full height */
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.contact-us-background {
  background: linear-gradient(135deg, #f0f8ff, #e6e6fa); /* Soft gradient background for contrast */
  flex: 1; /* Make sure this section grows to fill available space */
  color: #212428; /* Dark text color for contrast */
  padding-top: 1rem; /* Reduced padding at the top */
  padding-bottom: 5rem; /* Ensure padding at the bottom */
}

.card {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background for card */
  border-radius: 10px; /* Rounded corners */
  color: #212428; /* Dark text color for card */
  margin-top: 1rem; /* Reduced margin-top for card spacing */
  padding: 2rem; /* Add padding for better content spacing */
}

.card h1 {
  color: #f5a341; /* Brand color for headings */
}

.card p {
  color: #6c757d; /* Soft gray for paragraph text */
  font-size: 1.1rem; /* Slightly larger font size for better readability */
}

.form-container {
  margin-top: 1rem; /* Adjust margin-top for form spacing */
  text-align: center; /* Center the form */
}

iframe {
  border: none; /* Remove border from iframe */
  border-radius: 5px; /* Rounded corners for the iframe */
}
