
  
  .landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #212428;
    position: relative;
  }
  
  .landing-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none; /* Hide video initially */
  }
  
  .landing-video.playing {
    display: block; /* Show video when playing */
  }
  
  .video-thumbnail {
    width: 100%;
    height: 100%;
    background-color: #000; /* Fallback background color */
    position: relative;
    cursor: pointer;
  }
  
  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .play-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
  
  .play-button {
    width: 0;
    height: 0;
    border-left: 25px solid white;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
  