
.about-us-background {
  background: linear-gradient(135deg, #f0f8ff, #e6e6fa); /* Soft gradient background for contrast */
  flex: 1; /* Make sure this section grows to fill available space */
  color: #212428; /* Dark text color for contrast */
  padding-top: 2rem; /* Adjust padding at the top */
  padding-bottom: 5rem; /* Ensure padding at the bottom */
}

.card {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background for card */
  border-radius: 10px; /* Rounded corners */
  color: #212428; /* Dark text color for card */
  margin-top: 1rem; /* Adjust margin-top for card spacing */
}

.card h1 {
  color: #212428; /* Dark text color for heading */
}

.about-us-title {
  color: #9b8f9b; /* Soft lavender color for "About Us" */
}

.get-my {
  color: #f5a341; /* Brand color for "Get My" */
}

.pandit {
  color: #b42b26; /* Brand color for "Pandit" */
}

.card p {
  color: #6c757d; /* Soft gray for paragraph text */
  font-size: 1.1rem; /* Slightly larger font size for better readability */
}
